
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = window.jQuery = require('jquery');

require('admin-lte/bower_components/jquery-ui/jquery-ui');
require('admin-lte/bower_components/jquery-sparkline/dist/jquery.sparkline');
require('admin-lte/bower_components/bootstrap/dist/js/bootstrap');
require('admin-lte/bower_components/datatables.net/js/jquery.dataTables')
require('admin-lte/bower_components/datatables.net-bs/js/dataTables.bootstrap')
require('admin-lte/bower_components/jquery-slimscroll/jquery.slimscroll')
require('admin-lte/bower_components/fastclick/lib/fastclick')
require('admin-lte/bower_components/bootstrap-colorpicker/dist/js/bootstrap-colorpicker')
require('admin-lte/bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker')
require('admin-lte/bower_components/bootstrap-daterangepicker/daterangepicker')
require('admin-lte/bower_components/PACE/pace')
require('admin-lte/bower_components/seiyria-bootstrap-slider/dist/bootstrap-slider')
require('admin-lte/bower_components/select2/dist/js/select2.full')
require('admin-lte/dist/js/adminlte');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

/**
 * !!! Add other dependencies here !!!
 */

window.bootbox = require('bootbox');
